<template>
  <!--  Start Call To Action -->
  <section class="call-to-action text-center">
    <div class="container">
      <i class="flaticon-lotus"></i>
      <div class="content">
        <h3>Become a Yoga Member</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut.
        </p>
        <router-link to="/about-us" class="main-btn custom-btn" target="_blank"><span>Join Now</span></router-link>
      </div>
    </div>
  </section>
  <!--  End Call To Action -->
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>